@charset "utf-8";
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css');

@import 'variables';
@import 'mixins';
@import 'input';
@import 'badges';
@import 'multiselect';
@import 'select2';
@import 'select';
@import 'table';
@import 'helpers';
@import 'modal';

//ScrollBar browser
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 0 !important;
  background-color: $corScrollbarBack;
}

::-webkit-scrollbar-thumb {
  background-color: $corScrollbar;
  border-radius: 8px;
  border: 3px solid transparent;
}

body {
  overflow-x: hidden;
  background-color: #f3f6f8 !important;
}

ol,
ul {
  padding-left: 0 !important;
}

h1,
h2,
h3,
h4,
h5 {
  color: $corPrincipal !important;
  font-weight: bolder !important;
}

p,
a,
small,
li {
  color: $textosSecundarios;
  word-break: break-all;
  line-height: 1.2;
  font-size: $fontSize;
  @media (max-width: $mobile) {
    font-size: calc($fontSize - 0.2rem);
  }
}

small {
  font-size: calc($fontSize - 0.2rem);
  @media (max-width: $mobile) {
    font-size: calc($fontSize - 0.3rem) !important;
  }
}

a {
  cursor: pointer !important;
  text-decoration: none !important;
}

.no-shadow {
  box-shadow: none !important;
  text-shadow: none !important;
}

.text-muted {
  color: $TextorColorDefault !important;
}

.text-ellipsis {
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-icon {
  font-size: 1.2rem;
  color: $corPrincipal !important;
}

.app-wrapper {
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  // @media (max-width: $mobile) {
  //   padding-top: $menuHeight + 20px;
  // }
}

.container-fluid {
  max-width: 1200px;
  @media (max-width: $mobile) {
    max-width: 500px;
  }
}

.row {
  --bs-gutter-x: 1rem !important;
}

.col {
  flex-grow: initial;
  flex-basis: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

.status-ball {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background: currentColor;
}

// ===== MENU =====
.body-painel {
  padding-top: $menuHeight + 20px;
  padding-bottom: 1rem;
  min-height: 100vh;
  padding-right: 1.5rem !important;

  @media (max-width: $mobile) {
    padding-bottom: 2rem;
  }
}

// ===== CARD =====
.card {
  background: $white;
  .card-header,
  .card-body,
  .card-footer {
    border: none !important;
    background: transparent;
  }

  &.card-custom {
    @media (min-width: $screenLG) {
      min-height: 150px;
    }
  }
}

// ===== BG =====
.bg-primaria {
  background-color: $baseColor;
  color: $textColorPrimary;
}

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/root';
@import '../../../node_modules/bootstrap/scss/reboot';
@import '../../../node_modules/bootstrap/scss/type';
@import '../../../node_modules/bootstrap/scss/images';
@import '../../../node_modules/bootstrap/scss/containers';
@import '../../../node_modules/bootstrap/scss/grid';

.hr-shadow {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

small.spinner-border {
  width: 20px;
  height: 20px;
}

.menu-options {
  border: 0px !important;
  font-size: 20px;
}

.loadOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.1);
}

.text-white::placeholder {
  color: white !important;
}

//===== Botões =====
.btn {
  padding: 1rem 2rem;
  border-radius: $borderRadius !important;
  @include btn-primary;

  &.btn-sm {
    padding: 10px;
    font-size: 0.78rem;
    &::after {
      display: none;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border: none !important;
  }

  &.btn-danger {
    background: #dc3545;
    color: $white;
    &:hover,
    &:focus,
    &:active {
      background: darken(#dc3545, 10%);
    }
  }
}

.btn-aba {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 500;
  color: $TextorColorDefault;
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    border-radius: 50px;
    background: $corPrincipal;
    transition: 0.3s;
    position: absolute;
    bottom: -3px;
  }
  &.active,
  &:hover,
  &:focus {
    color: $corPrincipal;
    &::after {
      width: 100%;
    }
  }

  @media (max-width: $mobile) {
    font-size: 1.1rem;
  }
}

.btn-select-file {
  color: $TextorColorDefault;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border: 2px solid $TextorColorDefault;
  padding: 0.2rem 0.5rem;
  border-radius: $borderRadius;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  @media (min-width: 450px) {
    max-width: 250px;
  }
}

.form-group {
  position: relative;
  .btn-inline {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    box-shadow: none;
    align-items: center;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none !important;
    }
  }
}

.btn-primaria {
  background-color: $baseColor;
  color: $textColorPrimary;
  &:hover,
  &:disabled {
    background-color: $baseColor;
    color: $textColorPrimary;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  }
}

.btn-secundaria {
  background-color: $corPrincipal;
  color: $textColorPrimary;
  &:hover,
  &:disabled {
    background-color: $corPrincipal;
    color: $textColorPrimary !important;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  }
}

.btn-login {
  background-color: $baseColor;
  color: $textColorSecondary;
  &:hover {
    background-color: $baseColor;
    color: $textColorSecondary;
  }
}

.btn-card {
  background-color: $baseColor !important;
  box-shadow: none !important;
  border: 3px solid transparent !important;
  transition: all 0.25s ease;
  &:hover {
    color: $white !important;
    border: 3px solid $bgBtn !important;
    h3,
    h5,
    p,
    a {
      color: $white !important;
    }
  }
  &.active {
    color: $white !important;
    border: 3px solid $bgBtn !important;
    h5,
    h3,
    p,
    a {
      color: $white !important;
    }
  }
}

.btn-top-header-card {
  border-top-right-radius: 0.35rem !important;
}

.btn-top-header-card-left {
  border-top-left-radius: 0.375rem !important;
}

.btn-tools {
  background-color: $white !important;
  color: #586064;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    background-color: $white !important;
    color: $corPrincipal !important;

    box-shadow: none !important;
    border: none !important;
  }

  &--no-bg {
    background: transparent !important;
    &:hover,
    &:focus,
    &:active {
      background: transparent !important;
    }
  }

  &--red {
    background: transparent !important;
    color: #dc3545;
    &:hover,
    &:focus,
    &:active {
      color: #dc3545 !important;
      background: transparent !important;
    }
  }

  &--green {
    color: green;
    background: transparent !important;

    &:hover,
    &:focus,
    &:active {
      color: green !important;
      background: transparent !important;
    }
  }
}

//====== CARDS GERAIS ======

.card {
  // border: 1px solid #ddd9d9 !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  &--no-shadow {
    box-shadow: none !important;
  }
}

//====== HR GERAIS ======
hr {
  width: 100%;
  height: 1px;
  border: 0px;
  border-top: 1px solid #ddd9d9;
  background-color: #fff;
  opacity: 1;
  margin: 0.5rem auto;
}

//====== Wrapper Input Upload ======
.wrapper-upload {
  padding: 1rem;
  color: dimgray;
  background: #fff;
  border: 1px solid dimgray !important;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 50px;
  span {
    font-size: 1rem;
    small {
      font-size: 1rem;
    }
  }
}

//====== PAGIANTION ======
.pagination {
  gap: 8px;
  .page-item {
    &.active {
      .page-link {
        background-color: $corPrincipal !important;
        border: none !important;
        color: $white;
      }
    }
    .page-link {
      border-radius: 50%;
      box-shadow: none !important;
      color: $corPrincipal;
    }
  }
}

//====== POPPER ======
.popper {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #000000;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 20px;
  font-weight: bold !important;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.popper:hover {
  --popper-theme-background-color: #f7f7f7;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #000000;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 20px;
  font-weight: bold !important;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.text-main-color {
  color: $corPrincipal;
}

@media print {
  body * {
    visibility: hidden;
  }
  .noprint {
    display: none !important;
  }
  #printable,
  #printable * {
    visibility: visible;
  }
  #printable {
    width: 100%;
    height: 100%;
    page-break-after: always;
  }

  .container {
    max-width: 97% !important;
    padding: 25px !important;
  }

  // @page {
  //   size: ;
  //   margin: 0;
  // }
}

// @media print {
//   body * {
//     visibility: hidden;
//   }
//   #printable,
//   #printable * {
//     visibility: visible;
//   }
//   #printable {
//     position: fixed;
//     left: 0;
//     top: 0;
//     overflow-y: auto;
//   }
// }
