.modal {
  z-index: 99999;
  background: rgba(0, 0, 0, 0.6);

  .modal-content {
    background-color: $bgModal;
    input {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    }
    .modal-header {
      border-bottom: none;

      .btn-close {
        outline: none !important;
        transition: all 0.7s ease;
        transform-origin: center center;
        &:hover {
          transition-delay: 0.2s;
          transform: scale(1.2) rotate(90deg);
        }
      }
    }

    .modal-footer {
      border-top: none;
    }
  }
}
