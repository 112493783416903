
@import '@/assets/sass/global.scss';

.notificate {
  margin: 0 5px 5px;
  padding: 15px;
  font-size: 20px;
  color: #ffffff;
  border-radius: 0.35rem;

  // default (blue)
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  // types (green, amber, red)
  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }
}
