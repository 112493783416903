@import 'variables';

// ===== ERROR FORM =====
span[role='alert'] {
  background-color: red;
  padding: 2px 7px;
  font-size: 0.7rem;
  color: white;
  border-radius: calc($borderRadius - 0.2rem) !important;
}

// Limitador centralizado de conteudo
.center {
  max-width: $maxWidth;
  margin: 0 auto;
  position: relative;
}

.text-center {
  text-align: center;
}

.only-desk {
  display: block !important;
  @media (max-width: $mobile) {
    display: none !important;
  }
}
.only-desk-flex {
  display: flex !important;
  @media (max-width: $mobile) {
    display: none !important;
  }
}
.only-desk-flex--lg {
  display: flex !important;
  @media (max-width: $screenLG) {
    display: none !important;
  }
}

.only-mob {
  display: none !important;
  @media (max-width: $mobile) {
    display: block !important;
  }
}
.only-mob-flex {
  display: none !important;
  @media (max-width: $mobile) {
    display: flex !important;
  }
}
.only-mob-flex--lg {
  display: none !important;
  @media (max-width: $screenLG) {
    display: flex !important;
  }
}

.opacity0 {
  opacity: 0;
}

.visibility0 {
  visibility: hidden;
}
