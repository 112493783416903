span.select2-container {
  display: block !important;
  color: #000000;
  width: 100% !important;
}

.select2-selection {
  border-radius: 1rem !important;
  span {
    font-size: $fontInput !important;
    color: $inputcolor !important;
  }
}

.select2-selection__rendered {
  padding-top: 22px !important;
  padding-left: 13px !important;
}

.select2-dropdown {
  border: none !important;
  padding: 0 !important;
  top: 19px !important;
  border-radius: 0.5rem !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: 0px 11px 42px 6px rgba(0, 0, 0, 0.25) !important;
  z-index: 40 !important;
  overflow: hidden;
}

.select2-search {
  &--dropdown {
    padding: 10px 7px !important;
  }

  .select2-search__field {
    height: 30px !important;
    border-radius: 0.5rem !important;
    box-shadow: none !important;
    &:focus {
      border-bottom: 1px solid #aaa !important;
    }
  }
}
