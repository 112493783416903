.multiselect__tag,
.multiselect__option--highlight {
  background: $corPrincipal !important;
}

.multiselect__option--highlight {
  &::after {
    display: none !important;
  }
}

.multiselect__tag-icon {
  &:hover,
  &:focus {
    background: $corPrincipal !important;
  }
  &::after {
    color: $textosPrincipais !important;
  }
}
