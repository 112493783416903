.vs__dropdown-toggle {
  border: 0 !important;
  height: 40px !important;
  @include input-primary;
  background-color: $inputBg !important;
  display: flex;
  padding: 0;
  white-space: normal;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.vs__actions {
  align-items: center;
  display: flex;
  padding: 4px 6px 0 3px;
  button {
    border: none;
  }
}

.vs--searchable .vs__dropdown-toggle {
  cursor: pointer !important;
}

.vs--open .vs__open-indicator {
  transform: rotate(180deg) scale(1);
}

.vs__open-indicator {
  fill: $inputcolor !important;
  transition: transform 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.v-select {
  border: none;
  border-radius: 0.5rem;
  height: $inputHeight;
  position: relative;
  .vs__dropdown-toggle {
    border-radius: $borderRadius;
  }
  &.vs--open {
    .vs__dropdown-toggle {
      border-radius: 10px 10px 0 0 !important;
      background: $white;
    }
  }
}

.vs__dropdown-menu {
  font-family: $font !important;
  font-size: 18px;
  background: $white;
  border-radius: 0 0 8px 8px !important;
  height: auto;
  min-height: 104px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  position: absolute;
  width: -webkit-fill-available;
  overflow-x: auto;
  border-left: none;
  border-right: none;
  border-bottom: none;
  top: 33px;
  left: -1px;
  right: -1px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 19px 15px;
}

.vs__dropdown-option {
  color: $corPrincipal;
  list-style: none;
  padding: 0 1rem;
  text-wrap: nowrap;
  font-size: 1rem !important;
  cursor: pointer;
  &:hover,
  &.vs__dropdown-option--highlight {
    background-color: $corPrincipal;
    color: $white;
    width: 100% !important;
    min-width: max-content !important;
  }
  // &.vs__dropdown-option--highlight {
  //   background: $corPrincipal;
  //   color: $white !important;
  //   // width: 43vw !important;
  //   @media (max-width: 768px) {
  //     // width: 100vw !important;
  //   }
  // }
}

.vs__search,
.vs__search:focus {
  //font-size: 25px;
  font-size: $fontInput !important;
  margin: 0 !important;
  // display: none;
  border: 0 !important;
  position: absolute;
  left: 0;
  width: 100% !important;
  box-shadow: none !important;
  height: 72%;
  max-height: 250px;
  top: 9px;
  background-color: transparent;
  padding: 0 0 0 13px;
}

.vs__selected-options {
  overflow: hidden !important;
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0 2px;
  position: relative;
  height: $inputHeight;
}

.vs__selected {
  margin: 0 !important;
  font-size: $fontInput;
  color: $inputcolor !important;
  padding: 0.7em 0.25em 0.8em 12px !important;
  line-height: 1.5 !important;
  position: absolute !important;
  top: 1px;
  // max-width: 40ch !important;
  white-space: nowrap !important;
  // @media (max-width: 1120px) {
  //   line-height: 2 !important;
  // }
}

.vs--single.vs--open .vs__selected {
  // position: static !important;
  opacity: 0.4;
}

.vs--single.vs--searching .vs__selected {
  display: none;
}

.vs__clear {
  box-shadow: none !important;
  background: none !important;
  &:hover,
  &:focus,
  &:active {
    box-shadow: none !important;
    background: none !important;
  }
  svg {
    path {
      fill: $black;
    }
  }
}

.vs__no-options {
  text-align: center;
}
