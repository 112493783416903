.table {
  background: transparent;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  overflow-x: auto;

  > :not(caption) > * > * {
    padding: 0;
  }

  thead th,
  td {
    vertical-align: middle;
  }
  thead {
    tr {
      background: $corPrincipal;
      th {
        // font-size: $fontSize;
        // padding: 5px 35px;
        // text-align: center;
        // font-weight: normal;
        // color: $white;
        // font-family: $fontB;
        // height: 40px;
        // @media (max-width: $mobile) {
        //   font-size: 15px;
        //   line-height: 18px;
        // }

        i {
          font-size: 0.6rem;
        }
      }
    }
  }
  tbody {
    tr {
      background-color: #ececec;
      td {
        // font-size: $fontSize;
        // padding: 5px;
        // text-align: center;
        // color: $corPrincipal;
        // font-family: $fontB;
        height: 40px;
        // border-bottom: none;
        // border-right: 1px solid $corPrincipal;
        // @media (max-width: $mobile) {
        //   font-size: 15px;
        //   line-height: 18px;
        // }
      }
    }
  }
}
