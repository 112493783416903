@import 'variables';

// ===========================================================================

// BORDER RADIUS
@mixin borderR($br: $borderRadius) {
  -webkit-border-radius: $br !important;
  -moz-border-radius: $br !important;
  border-radius: $br !important;
}

// ===========================================================================

// BOX SHADOW
@mixin boxShadow(
  $horz: 0px,
  $vert: 2px,
  $blur: 4px,
  $size: 1px,
  $rgb: rgba(0, 0, 0, 0.3)
) {
  -webkit-box-shadow: $horz $vert $blur $size $rgb;
  -moz-box-shadow: $horz $vert $blur $size $rgb;
  box-shadow: $horz $vert $blur $size $rgb;
}

// ===========================================================================

// ===========================================================================

// INPUT
@mixin input-primary() {
  @include borderR(0.5rem);
  height: $inputHeight;
  background-color: $inputBg;
  color: $inputcolor !important;
  outline: none;
  border: none !important;
  font-size: $fontInput;
  font-family: $fontText;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  z-index: unset !important;
  width: -webkit-fill-available;
  max-width: 100%;
  padding: 0 12px;
  &::placeholder {
    color: $inputcolor !important;
  }
}

//FLOATING LABEL
.form-floating > label {
  z-index: 41 !important;
  padding-top: 0px !important;
  font-size: 0.7rem !important;
  color: $white !important;
  background: $corPrincipal;
  height: 16px !important;
  top: -9px !important;
  left: 6px;
  border-radius: calc($borderRadius - 0.2rem);
  border: none !important;
  transform: none !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

// BUTTON
@mixin btn-primary(
  $height: fit-content,
  $maxHeight: 40px,
  $padding: 10px 25px,
  $font-size: $fontSize,
  //21px,
  $line-height: normal
) {
  @include borderR();
  box-shadow: none;
  border: 1px solid $bgBtn !important;
  background: transparent;
  padding: $padding;
  height: $height;
  max-height: $maxHeight;
  font-size: $font-size;
  line-height: $line-height;
  color: $bgBtn;
  font-family: $font;
  font-weight: 600;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  width: auto;
  max-width: fit-content;
  max-width: -moz-fit-content;
  z-index: 1;
  align-items: stretch;
  justify-content: center;
  margin: 0;
  text-transform: uppercase;
  transition: all 0.25s ease;

  &:hover,
  &:focus,
  &:active,
  &.active {
    background: $bgBtn;
    background-color: $bgBtn !important;
    outline: none;
    color: $btColor !important;
    box-shadow: none !important;
  }

  @media (max-width: $mobile) {
    padding: 10px;
    font-size: 0.85rem;
  }
}

// SELECT
@mixin select-primary() {
  @include borderR();
  height: $inputHeight;
  color: $inputcolor;
  outline: none;
  border: none;
  font-family: $fontB;
}

// TEXTAREA
@mixin text-area-primary() {
  @include borderR();
  @include boxShadow();
  outline: none;
  border: none;
  font-size: $fontSize;
  font-family: $fontText;

  &::placeholder {
    color: $inputcolor;
    font-size: $fontSize;
    //font-weight: bold;
  }
}

// ===========================================================================
